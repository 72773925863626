import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { visuallyHidden } from "@mui/utils";
import AuthorsTbl from "./AuthorsTbl";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import { isKore } from "../../utils";
import HSBar from "react-horizontal-stacked-bar-chart";
import logo from "../../analyticsIcon2.gif";
function descendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function acendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => acendingComparator(a, b, orderBy);
}

const headCells = [];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    //console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"right"}
            //padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align={"left"}
            > */}
            <h4> {headCell.label}</h4>
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = ({ extended }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log(searchParams.entries());

  const [tbl1Data, setTbl1Data] = React.useState([]);
  const [tbl2Data, setTbl2Data] = React.useState([]);
  const [tbl3Data, setTbl3Data] = React.useState([]);

  const updateData = async (articleIds) => {
    setIsLoading(true);

    (async () => {
      try {
        const today = new Date();
        const ddd = await API.getAuthorsCount(today, today);
        setTbl1Data(ddd ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
    (async () => {
      try {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const ddd = await API.getAuthorsCount(yesterday, yesterday);
        setTbl2Data(ddd ?? []);
      } catch (e) {
        console.error(e);
      }
    })();
    (async () => {
      try {
        const mStart = new Date();
        mStart.setDate(0);
        const ddd = await API.getAuthorsCount(mStart, new Date());
        setTbl3Data(ddd ?? []);
      } catch (e) {
        console.error(e);
      }
    })();

    setIsLoading(false);
    setTimeout(updateData, 70 * 1000, articleIds);
  };

  React.useEffect(async () => {
    const _articleIds = searchParams.get("articles") || "";
    setArticleIds(_articleIds);
  }, [searchParams]);

  React.useEffect(async () => {
    updateData();
  }, []);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("count");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [firstTimeload, setFirstTimeload] = React.useState();
  const [showOnlyNews, setShowOnlyNews] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(999);
  const [totalDevicesPrecentage, setTotalDevicesPrecentage] = React.useState({
    desktop: 0,
    mobile: 0,
    tablet: 0,
  });
  const [articleIds, setArticleIds] = React.useState(searchParams.get("articles") || "");
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const isMobile = window.innerWidth < 700;
  console.log(isMobile);
  return (
    <div>
      {/* <Box sx={{ width: "95%", "max-width": "2000px", margin: "auto", marginTop: "20px" }}>ggg</Box> */}
      <Box
        sx={{
          width: "95%",
          "max-width": "2000px",
          margin: "auto",
          marginTop: extended ? "10px" : "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: extended ? "0px" : "10px",
          }}
        >
          {!extended ? (
            <>
              {/* <img height={"50px"} width={"auto"} src={logo}></img> */}
              <h1 style={{ fontWeight: "bold", marginRight: "15px", marginLeft: "15px" }}>
                כמות כתבות לפי כתב{" "}
              </h1>
              <img
                height={"50px"}
                width={"auto"}
                style={{ borderRadius: "9px" }}
                src={isKore ? "https://i.imgur.com/PTmf6qd.png" : "https://i.imgur.com/AvvXZkl.png"}
              ></img>
            </>
          ) : (
            <h2 style={{ fontWeight: "bold", marginRight: "15px" }}>כתבות נעוצות</h2>
          )}
        </div>
        <center>{/* <h1>גוגל דיסקבר</h1> */}</center>

        <br></br>
        <div
          style={{
            display: !isMobile ? "flex" : "block",
            justifyContent: "space-between",
            maxWidth: "1250px",
            margin: "auto",
          }}
        >
          <AuthorsTbl rows={tbl2Data} title="מאתמול" />
          <AuthorsTbl rows={tbl1Data} title="מהיום" />
          <AuthorsTbl rows={tbl3Data} title="מתחילת החודש" />
        </div>
      </Box>
    </div>
  );
};
export default EnhancedTable;
