import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { visuallyHidden } from "@mui/utils";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import { isKore } from "../../utils";
import HSBar from "react-horizontal-stacked-bar-chart";
import logo from "../../analyticsIcon2.gif";
function descendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function acendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => acendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    label: "מס",
  },
  {
    id: "title",
    numeric: false,
    label: "כותרת",
  },
  {
    id: "url",
    numeric: false,
    label: "קישור",
  },
  {
    id: "author",
    numeric: false,
    label: "שם הכתב",
  },
  {
    id: "pubdate",
    numeric: false,
    label: "תאריך פרסום",
  },
  {
    id: "views",
    numeric: true,
    label: "צפיות",
  },
  {
    id: "ofviews",
    numeric: true,
    label: "סך מכל הצפיות",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    //console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"right"}
            //padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align={"left"}
            >
              <h4> {headCell.label}</h4>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = () => {
  const flatten = (data) => {
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    const newData = groupBy(data, "link");
    const new_ = [];
    //return new_;
    Object.entries(newData).map(([url, d], idx) => {
      d = d.sort();
      let views = 0;
      let ofviews = 0;
      let url_ = "";
      d.forEach((c) => {
        views += c.views;
        ofviews += c.ofviews;
        //if (c.googleTitle.includes("/")) {
        url_ = c.googleTitle;
        //}
      });
      new_.push({ link: url, views, ofviews, id: idx, googleTitle: d[d.length - 1].googleTitle });
    });
    return new_;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log(searchParams.entries());

  const updateData = async (articleIds) => {
    setIsLoading(true);
    try {
      const data = await API.getAnalyticsRealtimePagesData(articleIds);
      const rows = data.rows ?? [];
      if (rows.length == 0) throw new Error();
      const total = data["totalsForAllResults"]?.["rt:pageviews"] || 0;
      const flattenRows = flatten(
        rows.map((d, idx) => {
          // if (d[2] == "DESKTOP") desktop++;
          // if (d[2] == "TABLET") tablet++;
          // if (d[2] == "MOBILE") mobile++;

          return {
            link: !isKore ? "https://www.bhol.co.il" + d[0] : "https://www.kore.co.il" + d[0],
            views: Number(d[2]),
            id: idx + 1,
            googleTitle: d[1],
            ofviews: (Number(d[2]) / total) * 100,
          };
        })
      );

      // setTotalDevicesPrecentage({
      //   desktop: (desktop / rows.length) * 100,
      //   mobile: (mobile / rows.length) * 100,
      //   tablet: (tablet / rows.length) * 100,
      // });
      setRows(flattenRows);
    } catch (error) {}
    setIsLoading(false);
    setTimeout(updateData, 31.5 * 1000, articleIds);
  };
  const [displayedActiveUsers, setDisplayedActiveUsers] = React.useState(0);

  React.useEffect(async () => {
    const _articleIds = searchParams.get("articles") || "";
    setArticleIds(_articleIds);
  }, [searchParams]);

  React.useEffect(async () => {
    updateData();
  }, []);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("views");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [firstTimeload, setFirstTimeload] = React.useState();
  const [showOnlyNews, setShowOnlyNews] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [totalDevicesPrecentage, setTotalDevicesPrecentage] = React.useState({
    desktop: 0,
    mobile: 0,
    tablet: 0,
  });
  const [articleIds, setArticleIds] = React.useState(searchParams.get("articles") || "");
  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const updateArticleDataForRow = async (articleLink) => {
    setRows(
      [...rows].map((r) => {
        if (r.link != articleLink) return r;

        r.isLoading = true;
        return r;
      })
    );

    var link = articleLink.split("co.il/")[1];

    const { title, author, pub_date, pub_time } = await API.getArticleData(
      encodeURI(link?.replace(!isKore ? "news/" : "viewArticle/", ""))
    );

    setRows(
      [...rows].map((r) => {
        if (r.link != articleLink) return r;
        r.title = title || r.googleTitle;
        r.author = author || "-";
        r.pubdate = pub_date ? pub_time + " " + pub_date || "" : "-";
        return r;
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const isMobile = window.innerWidth < 670;
  return (
    <div>
      {/* <Box sx={{ width: "95%", "max-width": "2000px", margin: "auto", marginTop: "20px" }}>ggg</Box> */}
      <Box sx={{ width: "95%", "max-width": "2000px", margin: "auto", marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "0px",
          }}
        >
          {/* <img height={"50px"} width={"auto"} src={logo}></img> */}
          <h2 style={{ fontWeight: "bold", marginRight: "15px" }}>צפיות ב30 הדקות האחרונות</h2>
        </div>
        <center>{/* <h1>גוגל דיסקבר</h1> */}</center>

        <br></br>

        <Paper sx={{ mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: isMobile ? "10px" : "",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOnlyNews}
                      onClick={(e) => setShowOnlyNews(e.target.checked)}
                    />
                  }
                  label="הצג רק כתבות"
                />
              </FormGroup>
              <div>
                <TextField
                  id="outlined-basic"
                  label="מזהה כתבה"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setArticleIds(e.target.value)}
                  value={articleIds}
                  sx={{ width: isMobile ? "100%" : "unset" }}
                />
                {/* <Button
                  variant="contained"
                  color="success"
                  sx={{
                    marginRight: !isMobile ? "5px" : "",
                    marginTop: isMobile ? "10px" : "",
                    width: isMobile ? "100%" : "",
                  }}
                  onClick={() => {
                    setSearchParams({
                      articles: articleIds,
                    });
                    //updateData();
                  }}
                  disabled={isLoading}
                >
                  חיפוש
                </Button> */}
              </div>
            </div>
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                //onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

              <TableBody>
                {rows
                  .filter((j) => {
                    if (showOnlyNews) return j.link.includes(isKore ? "viewArticle/" : "news/");

                    return true;
                  })
                  .filter((j) => {
                    if (articleIds) {
                      return j.link.includes("/" + articleIds);
                    } else {
                      return true;
                    }
                  })
                  // .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    if (!row.isLoading) updateArticleDataForRow(row.link);
                    return (
                      <TableRow hover key={index}>
                        <TableCell
                          align="right"
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          onClick={() => {
                            const articleId = row.link.split(".co.il/")[1];
                            setArticleIds(articleId);

                            setSearchParams({
                              articles: articleId,
                            });
                            //updateData();
                          }}
                        >
                          {row.id}
                        </TableCell>

                        <TableCell align="right">{row.title || "טוען.."}</TableCell>
                        <TableCell align="right">
                          <a target="_blank" href={row.link}>
                            {row.link.split(".co.il")[1]}
                          </a>
                        </TableCell>
                        <TableCell align="right">{row.author || "טוען.."}</TableCell>
                        <TableCell align="right">{row.pubdate || "טוען.."}</TableCell>
                        <TableCell align="right">
                          <b>{row.views.toLocaleString()}</b>
                        </TableCell>
                        <TableCell align="right">{row.ofviews.toFixed(2)}%</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};
export default EnhancedTable;
