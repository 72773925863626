import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { visuallyHidden } from "@mui/utils";
import { useSearchParams } from "react-router-dom";
import API from "../../api";
import { isKore } from "../../utils";
import HSBar from "react-horizontal-stacked-bar-chart";
import logo from "../../analyticsIcon2.gif";
function descendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function acendingComparator(a, b, orderBy) {
  // orderBy = "ctr";
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => acendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "_",
    numeric: false,
    label: "",
  },
  {
    id: "name",
    numeric: false,
    label: "שם כתב",
  },
  {
    id: "total",
    numeric: true,
    label: "כתבות",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    //console.log(property);
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"right"}
            //padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              align={"left"}
            >
              <h4> {headCell.label}</h4>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = ({ extended, rows, title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  //console.log(searchParams.entries());

  const updateData = async (articleIds) => {
    setIsLoading(true);
    try {
    } catch {}
    setIsLoading(false);
    setTimeout(updateData, 63.5 * 1000, articleIds);
  };

  React.useEffect(async () => {
    const _articleIds = searchParams.get("articles") || "";
    setArticleIds(_articleIds);
  }, [searchParams]);

  React.useEffect(async () => {
    updateData();
  }, []);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("total");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [selectedRow, setSelectedRow] = React.useState();

  const [articleIds, setArticleIds] = React.useState(searchParams.get("articles") || "");
  // const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sumChildren = (r) => {
    if (r.children.length == 0) return r.count;
    let c = Number(r.count);
    r.children.forEach((ca) => (c += ca.count));
    return c;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const isMobile = window.innerWidth < 670;
  return (
    <div style={{ marginBottom: "7px" }}>
      <center>
        <h3 style={{ marginBottom: "7px" }}>{title}</h3>
      </center>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 20 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              //onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {rows
                // .slice()
                .map((r) => ({ ...r, total: sumChildren(r) }))
                .filter((r) => r.total != 0)
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  if (typeof row == "string")
                    return (
                      <TableRow>
                        <h3 style={{ marginRight: "15px" }}>{row}</h3>
                      </TableRow>
                    );
                  return (
                    <>
                      <TableRow
                        hover
                        key={index}
                        style={{
                          background: row.color,
                          borderBottom1: row._id == selectedRow ? "solid 2px orange" : "",
                          borderTop1: row._id == selectedRow ? "solid 2px red" : "",
                          cursor: row.children.length == 0 ? "auto" : "pointer",
                          background: row._id == selectedRow ? "#fb923c" : "",
                        }}
                        onClick={() => {
                          if (row.children.length == 0) return;
                          setSelectedRow((s) => {
                            if (s == row._id) return null;
                            return row._id;
                          });
                        }}
                      >
                        {/* <TableCell
                        align="right"
                        // style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                        onClick={() => {}}
                      >
                        {row.id}
                      </TableCell> */}

                        <TableCell
                          style={{
                            fontSize: "15px",
                            color: row.children.length
                              ? row._id == selectedRow
                                ? "white"
                                : "orange"
                              : "grey",
                            fontWeight: "bold",
                            opacity: row.children.length ? 1 : 0.3,
                          }}
                          align="right"
                        >
                          {row._id == selectedRow
                            ? `- ${row.children.length}`
                            : `+ ${row.children.length != 0 ? row.children.length : ""}`}
                        </TableCell>

                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">
                          <b>{sumChildren(row).toLocaleString() || "0"}</b>
                        </TableCell>
                      </TableRow>

                      {row.children.length != 0 &&
                        row._id == selectedRow &&
                        [row, ...row.children].map((cl, idxCl) => (
                          <TableRow
                            hover
                            key={index}
                            style={{
                              background: row._id == selectedRow ? "#fed7aa" : "",
                              borderBottom1:
                                idxCl == row.children.length - 1 ? "solid 2px orange" : "",
                            }}
                          >
                            <TableCell align="right"></TableCell>

                            <TableCell align="right">{cl.name}</TableCell>
                            <TableCell align="right">
                              <b>{cl.count?.toLocaleString() || "0"}</b>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 50, 100]}
          component="div"
          style={{ marginRight: isMobile ? "" : "10px" }}
          count={
            rows.map((r) => ({ ...r, total: sumChildren(r) })).filter((r) => r.total != 0).length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
export default EnhancedTable;
